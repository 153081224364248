import React, { useState, useEffect } from 'react';
import './ProductView.css';

const images = [
  "./Img2/iPhone0.jpg",
  "./Img2/iPhone1.jpg",
  "./Img2/iPhone2.jpg",
  "./Img2/iPhone3.jpg",
  "./Img2/iPhone4.jpg",
  "./Img2/iPhone5.jpg",
];

const Product360 = ({Data,ActiveImage360}) => {
  const [is360Main, set360Main] = useState(false)
  const [startX, setStartX] = useState(0);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [autoRotation, setAutoRotation] = useState(true);
  const [startX2, setStartX2] = useState(0);
  const [currentRotation, setCurrentRotation] = useState(0);
  const [isMouseOverImage, setIsMouseOverImage] = useState(false);
  const [ImageArray,setImageArray] = useState([]);
  useEffect(() => {
    console.log("Cureent Index", currentImageIndex)
    let intervalId;

    if (autoRotation) {
      intervalId = setInterval(() => {
        setCurrentImageIndex((currentImageIndex + 1) % Data.original_images.length);
      }, 1000); // Adjust the speed of auto-rotation as needed (higher interval for slower rotation)
    }

    return () => clearInterval(intervalId);
  }, [autoRotation, currentImageIndex]);

  const handleMouseDown = (e) => {
    setStartX(e.clientX);
    setAutoRotation(false);
  };

  const handleTouchStart = (e) => {
    setStartX(e.touches[0].clientX);
    setAutoRotation(false);
  };

  const handleMouseUp = (e) => {
    const deltaX = e.clientX - startX;
    if (deltaX > 50) {
      setCurrentImageIndex(currentImageIndex === 0 ? ImageArray.length - 1 : currentImageIndex - 1);
    } else if (deltaX < -50) {
      setCurrentImageIndex((currentImageIndex + 1) % ImageArray.length);
    }
    setAutoRotation(false);
  };

  const handleTouchEnd = (e) => {
    const deltaX = e.changedTouches[0].clientX - startX;
    if (deltaX > 50) {
      setCurrentImageIndex(currentImageIndex === 0 ? ImageArray.length - 1 : currentImageIndex - 1);
    } else if (deltaX < -50) {
      setCurrentImageIndex((currentImageIndex + 1) % ImageArray.length);
    }
    setAutoRotation(false);
  };
// .............................................................
  

  const handleMouseMove2 = (e) => {
    const deltaX = e.clientX - startX;
    setCurrentRotation(currentRotation + deltaX + 20);
    setStartX(e.clientX);
  };

  const handleMouseDown3 = (e) => {
    if (isMouseOverImage) {
      setStartX(e.clientX);
      document.addEventListener('mousemove', handleMouseMove3);
      document.addEventListener('mouseup', handleMouseUp);
    }
   
  };
  
  const handleMouseMove3 = (e) => {
    const deltaX = e.clientX - startX;
    setCurrentRotation(currentRotation + deltaX + 20);
    setStartX(e.clientX);
  };
  
  const handleMouseUp3 = () => {
    document.removeEventListener('mousemove', handleMouseMove3);
    document.removeEventListener('mouseup', handleMouseUp3);
  };
  

  const handleTouchStart2 = (e) => {
    setIsMouseOverImage(true)
    setStartX(e.touches[0].clientX);
  };

  const handleTouchMove2 = (e) => {
    setIsMouseOverImage(true)
      const deltaX = e.touches[0].clientX - startX;
    setCurrentRotation(currentRotation + deltaX);
    setStartX(e.touches[0].clientX);
    
    
  };

  const handleTouchEnd2 = () => {
    setIsMouseOverImage(false)
    // Handle touch end if needed
  };

 useEffect(()=>{
  console.log("Data Image",Data)
  setImageArray(Data.original_images)
  if(Data.id === 316){
    set360Main(true);
    
  }
  else{
    set360Main(false)
  }
 },[])
  return (

    <div
    onMouseEnter={() =>{
      console.log("Mouse Enter")
      setIsMouseOverImage(true)
    } }
      onMouseLeave={() => {
        console.log("Mouse Leave")
        setIsMouseOverImage(false)
      } }
    style={{display:"flex",justifyContent:"center",alignItems:"center"}}>

      {
        is360Main ? 
        <div
        className="product-view"
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
        onMouseUp={handleMouseUp}
        onTouchEnd={handleTouchEnd}
      >
        <img
          src={ImageArray[currentImageIndex]}
          alt="Product"
          draggable="false"
          className="product-image"
          style={{
            cursor: 'grab',
          }}
        />
      </div>
      :
        <div
        className="product-view"
        onMouseDown={handleMouseDown3}
        onTouchStart={handleTouchStart2}
        onTouchMove={handleTouchMove2}
        onTouchEnd={handleTouchEnd2}
      >
        <img
          src={ActiveImage360}
          draggable="false"
          alt="Product"
          className="product-image"
          style={{
            transform: `${ isMouseOverImage && `rotateY(${currentRotation}deg)` }`,
          }}
        />
      </div>
      }
       

  
    </div>
    
  );
};

export default Product360;

import React, { useState, useContext, useEffect } from "react";
import Header from "../common/Header";
import FooterDesktop from "../common/FooterDesktop";
import {  CatalogContext, SessionContext, UserContext } from "../../contexts";
import httpService from "../../services/httpService";


const Contact = () => {
  
  
  const { sessionId } = useContext(SessionContext);
  const { log, userDetails } = useContext(UserContext);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [contactForm, setContactForm] = useState({
    customer_id: "",
    username: "",
    mobile: "",
    subject: "",
    message: "",
  });

  useEffect(() => {
    if (log === 1 && userDetails) {
      let form = {
        customer_id: userDetails.customer_id,
        username: userDetails.firstname + " " + userDetails.lastname,
        mobile: userDetails.telephone,
        subject: "",
        message: "",
      };
      setContactForm(form);
    }
  }, [log]);

  const { showToast } = useContext(CatalogContext);

  const handleChange = (e) => {
    const form = { ...contactForm };
    form[e.target.name] = e.target.value;
    setContactForm(form);
  };

  const sendMessage = () => {
    const sendMasg = async () => {
      let form_data = new FormData();

      for (let key in contactForm) {
        form_data.append(key, contactForm[key]);
      }
      try {
        const { data: response } = await httpService.post(
          "https://admin.ndfurnituremart.com/index.php?route=feed/rest_api/send_msg",
          form_data,
          { headers: { ...httpService.headers, "X-Oc-Session": sessionId } }
        );
        console.log(response);
        if (response && "success" in response && response.success === 1) {
          showToast("success", "Message revieved!", "");
          setContactForm({
            username: "",
            mobile: "",
            subject: "",
            message: "",
          });
        } else if (
          response &&
          "success" in response &&
          response.success !== 1
        ) {
          showToast("error", "Message not sent!", "Please try again later");
        }
      } catch (error) {
        console.log("error", error);
        if (error.response) {
          showToast("error", "Message not sent!", "Please try again later");
        } else {
          showToast("error", "Message not sent!", "Please try again later");
        }
      }
    };
    const keys = Object.keys(contactForm);
    // for (let i = 0; i < keys.length; i++) {
    //   if (contactForm[keys[i]] === "") {
    //     if(keys[i]==="customer_id"){
    //       showToast("error", `Login is required!`, "");
    //       setButtonsDisabled(false);
    //       return false;
    //     }else{
    //       showToast("error", `${keys[i]} is required!`, "");
    //       setButtonsDisabled(false);
    //       return false;
    //     }
        
    //   }
    // }
    sendMasg();
    setButtonsDisabled(false);
  };
  return (
    <div>
      <Header />
      <section className="mh-70">
        <div className="container mb-30">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="mt-100">Contact Us</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10 offset-sm-1 col-12 offset-0 mt-30">
              <div className="row">
                <div className="col-sm-6 col-12">
                <iframe
  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13999.8338660507!2d76.947668!3d28.690889000000002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x30b2fd6a4289a1e0!2sND%20CHAIR%20PARTS%20SOLUTIONS%20PVT%20LTD!5e0!3m2!1sen!2sus!4v1665496121244!5m2!1sen!2sus"
  style={{ border: 0, width:'100%', height:'300px' }}
  allowFullScreen=""
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
/>
                 </div>
                <div className="col-sm-6 col-12">
                  <p className="text-description font-16">
                    <font className="font-20 text-gold" >ND Furniture Mart</font>
                    <br /><br />
                    Miac-2436, Plot No. 2435, Mie Part A,
                    <br />
                    Bahadurgarh - 124507, Haryana, India
                    <br />
                    
                   
                  
                    <br />
                    <a tel="+919560964505" className="text-description">
                    <i className="bi bi-phone"></i> +91 95609 64505
                    </a>
                   
                   
                    <br />
                  
                    <br />
                    <a href="mailto:info@codesilver.in" className="text-gold">
                    <i className="bi bi-envelope"></i>  info@ndfurnituremart.com
                    </a>
                    
                    
                    <br />
                    <br />
                     Collaborate with us for any business enquries.
                    <br />
                    
                    
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-10 offset-sm-1">
            <div className="card card-style">
            <div className="content">
              <h5 className="color-highlight font-13 mb-n1">
                Feedback / Enquiries
              </h5>
              <h2 className="font-700 pb-0">Send A Message</h2>
              <div className="form-custom form-label form-icon mb-3">
                <i className="bi bi-envelope font-14" />
                <input
                  type="text"
                  className="form-control rounded-xs"
                  id="c1"
                  placeholder="Name"
                  value={contactForm.username}
                  onChange={(e) => handleChange(e)}
                  name="username"
                />

                <span>(required)</span>
              </div>
              <div className="form-custom form-label form-icon mb-3">
                <i className="bi bi-envelope font-14" />
                <input
                  type="text"
                  className="form-control rounded-xs"
                  id="c1"
                  placeholder="Mobile"
                  value={contactForm.mobile}
                  onChange={(e) => handleChange(e)}
                  name="mobile"
                />

                <span>(required)</span>
              </div>
              <div className="form-custom form-label form-icon mb-3">
                <i className="bi bi-envelope font-14" />
                <input
                  type="text"
                  className="form-control rounded-xs"
                  id="c1"
                  placeholder="Subject"
                  value={contactForm.subject}
                  onChange={(e) => handleChange(e)}
                  name="subject"
                />

                <span>(required)</span>
              </div>
              <div className="form-custom form-icon mb-3">
                <i className="bi bi-pencil font-12" />
                <textarea
                  className="form-control rounded-xs"
                  placeholder="Message"
                  
                  value={contactForm.message}
                  onChange={(e) => handleChange(e)}
                  name="message"
                ></textarea>

                <span>(required)</span>
              </div>
              <button
                style={{ margin: "0 auto" }}
                disabled={buttonsDisabled}
                onClick={() => sendMessage()}
                className="btn btn-full btn-s rounded-s text-uppercase font-900 gradient-highlight shadow-bg shadow-bg-s"
              >
                <i className="bi bi-send"></i>{" "}
                {buttonsDisabled ? "Sending..." : "Send Message"}
              </button>
            </div>
          </div>

            </div>
          </div>
        </div>
      </section>
      <FooterDesktop />
    </div>
  );
};

export default Contact;
